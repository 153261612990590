<template>
  <v-dialog
    v-model="show"
    max-width="500px"
  >
    <v-card>
      <v-toolbar
        flat
        dense
        color="grey lighten-2"
      >
        <v-toolbar-title>
          Download TXT
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn
            icon
            small
            @click="show = false"
          >
            <v-icon v-text="'mdi-close'" />
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="mt-4">
        <validation-observer ref="observer">
          <v-row>
            <v-col>
              <validation-provider
                v-slot="{ errors }"
                vid="month"
              >
                <v-select
                  v-model="form.month"
                  :items="months"
                  item-value="id"
                  item-text="description"
                  label="Mês"
                  hide-details="auto"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col>
              <validation-provider
                v-slot="{ errors }"
                vid="year"
              >
                <v-select
                  v-model="form.year"
                  :items="years"
                  item-value="id"
                  item-text="description"
                  label="Ano"
                  hide-details="auto"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>
        </validation-observer>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="indigo"
          :loading="loading"
          outlined
          @click="download()"
        >
          <v-icon
            v-text="'mdi-download'"
            left
          />
          Download
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import date from '@/utils/date'
  import receiptsApi from '@/api/receipts'

  export default {

    props: {
      value: {
        type: Boolean,
        default: false,
      },
      city: {
        type: Object,
      },
    },

    data () {
      return {
        loading: false,
        months: date.months(),
        years: date.years(),
        form: {
          month: (new Date()).getMonth() + 1,
          year: (new Date()).getFullYear(),
        },
      }
    },

    computed: {
      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
    },

    methods: {

      async download () {
        try {
          this.loading = true

          const response = await receiptsApi.downloadTxt(this.form)

          const linkSource = `data:text/plain;base64,${response.data.file}`
          const downloadLink = document.createElement('a')
          const fileName = `famurs_txt_recibos_${this.form.month}${this.form.year}.txt`

          downloadLink.href = linkSource
          downloadLink.download = fileName
          downloadLink.click()

          this.show = false
        } catch (e) {
          if (e.response && e.response.status === 422) {
            return this.$refs.observer.setErrors(
              this.$apiError.fieldsValidation(e),
            )
          }

          const errorColor = e.response.status === 400 ? 'warning' : 'error'
          this.$snackbar.show({ color: errorColor, message: this.$apiError._(e) })
        } finally {
          this.loading = false
        }
      },

    },

  }
</script>
